<template>
  <v-container>
    <v-row class="pt-3">
      <v-app-bar
        flat
        dense
        color="transparent"
      >
        <h1 class="text-h5 primary--text">Gravações</h1>
      </v-app-bar>
      <v-col
        cols="12"
        lg="12"
      >
        <SectionTitle title="Cabeçalho" />
        <v-row>
          <v-col
            cols="12"
            lg="6"
          >
            <v-text-field
              v-model="printToSave.title"
              label="Título da página"
              rounded
              dense
              hide-details
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            lg="6"
          >
          <label class="subtitle-2 secondary--text">Descrição</label>
          <ckeditor
          v-model="printToSave.description"
          id="richText"
          :config="editorConfig"
          label="Descrição"
          rounded
          dense
          outlined
        />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            lg="6"
          >
            <v-col
              cols="12"
              md="12"
              class="text-right"
            >
              <v-select
                v-model="printToSave.status"
                :items="options"
                item-text="name"
                item-value="name"
                label="Tipo do Card"
                rounded
                outlined
                dense
              />
              <v-btn
                @click="onButtonClick"
                class="primary"
                rounded
                dense
                width="100%"
              >
                Adicionar imagem
                <v-icon
                  class="ml-4"
                  small
                  >fas fa-image</v-icon
                >
              </v-btn>
              <input
                ref="uploader"
                id="image_path"
                class="d-none"
                type="file"
                accept="image/*"
                @change="onFileChanged"
              />
              <v-col
                cols="12"
                md="12"
                class="d-inline-flex justify-center"
              >
                <v-avatar
                  class="u-cursor-pointer"
                  width="120px"
                  height="170px"
                  rounded
                  color="grey lighten-4 elevation-2"
                >
                  <img
                    v-if="print_image != null"
                    :src="print_image"
                    class="c-profile-picture"
                    alt="foto de perfil"
                  />

                  <v-icon
                    v-else
                    color="primary"
                  >
                    fas fa-image</v-icon
                  >
                </v-avatar>
              </v-col>
            </v-col>
            <v-row>
              <v-col cols="12">
                <v-btn
                  @click="savePrintCard"
                  class="primary"
                  rounded
                  dense
                  width="100%"
                >
                  <v-icon
                    class="ml-2 pr-2"
                    small
                    >fas fa-save</v-icon
                  >
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="12"
      >
        <SectionTitle title="Gravações da página" />
        <v-row>
          <v-col
            v-for="(item, idx) in list"
            :key="idx"
            cols="12"
            lg="3"
          >
            <v-dialog
              v-model="list[idx].printCardDialog"
              max-width="600"
              scrollable
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  v-on="on"
                  v-bind="attrs"
                  link
                >
                  <v-img
                    max-height="200"
                    :src="
                      list[idx].image_path == null
                        ? 'https://via.placeholder.com/300x300.jpeg'
                        : $store.state.imagePath + list[idx].image_path
                    "
                  />
                  <v-card-title class="justify-center">
                    <h2 class="primary--text text-subtitle-2">
                      {{ list[idx].title }}
                    </h2>
                  </v-card-title>
                </v-card>
              </template>

              <PrintCard
                @closeDialog="list[idx].printCardDialog = false"
                :item="list[idx]"
                :list="list"
              />
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
    PrintCard: () => import('./PrintCard'),
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    printCardDialog: false,
    printToSave: {
      title: null,
      description: null,
      image_path: null,
    },
    editorConfig: {
      toolbar: [['Bold', 'Italic', 'Strike', 'Styles', 'Format']],
    },
    options: [{ name: 'info' }, { name: 'post' }],
    list: [{ printCardDialog: false }],
    print_image: null,
  }),
  methods: {
    savePrintCard() {
      if (this.printToSave.image_path != null) {
        this.printToSave.status = 'post'
      } else {
        this.printToSave.status = 'info'
      }
      const formData = new FormData()
      const siteKeys = Object.keys(this.printToSave)
      const siteValues = Object.values(this.printToSave)
      for (let i = 0; i < siteKeys.length; i++) {
        if (siteValues[i] !== null) {
          formData.append(siteKeys[i], siteValues[i])
        }
      }

      this.$api
        .post(`site/print`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.getList()
          // location.reload()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onButtonClick() {
      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.printToSave.image_path = e.target.files[0]
      this.print_image = URL.createObjectURL(e.target.files[0])
    },
    getList() {
      this.$api
        .get('site/prints', {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.list = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.getList()
  },
}
</script>
